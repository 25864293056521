import React, { useMemo, useRef } from "react";
import { useCombobox } from "downshift";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  List,
  ListItem,
  ListItemProps,
  ListProps,
  Spinner,
  Text,
  forwardRef,
  Button,
} from "@chakra-ui/react";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ComboboxInput = forwardRef(({ ...props }: InputProps, ref: any) => {
  return <Input {...props} ref={ref} />;
});

const ComboboxList = React.forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ isOpen, py, ...props }: ListProps & { isOpen: boolean; py: number }, ref: any) => {
    return (
      <List
        bg="white"
        borderBottomRadius="md"
        display={isOpen ? undefined : "none"}
        py={py}
        {...props}
        ref={ref}
      />
    );
  }
);

const ComboboxItem = React.forwardRef(
  (
    {
      itemIndex,
      highlightedIndex,
      ...props
    }: ListItemProps & { itemIndex: number; highlightedIndex: number },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: any
  ) => {
    const isActive = itemIndex === highlightedIndex;

    return (
      <ListItem
        transition="background-color 220ms, color 220ms"
        bg={isActive ? "gray.100" : undefined}
        px={4}
        py={2}
        cursor="pointer"
        {...props}
        ref={ref}
      />
    );
  }
);

export interface AutoCompleteInputItem {
  value: string;
  label: string;
  group?: string;
}

type Props<T extends AutoCompleteInputItem> = {
  items: T[];
  value: string;
  onChange: (value: string) => void;
  renderItem?: (item: T, collapsed: boolean) => React.ReactNode | JSX.Element | null;
  renderCustomButton?: (onClick: () => void) => JSX.Element;
  isLoading?: boolean;
  onSelectedItem?: (item: T) => void;
  shouldFilter?: boolean;
  menuHeader?: React.ReactNode;
  placeholder?: string;
  disableTextInput?: boolean;
  onEnterPress?: () => void;
  disabled?: boolean;
  height?: string | number;
  width?: string | number;
  rightButton?: boolean;
  rightButtonDisabled?: boolean;
  onPressRightButton?: () => void;
  /** Set to true for the dropdown to show overtop of the other UI elements instead of pushing elements below lower */
  dropdownAsOverlay?: boolean;
  /** Set to true to show the renderItem result instead of the value text for the selected item */
  renderSelectedItem?: boolean;
  selectedItemValue?: string | null;
};

export default function AutoCompleteInput<T extends AutoCompleteInputItem>({
  onChange,
  items,
  value,
  renderItem,
  renderCustomButton,
  isLoading,
  onSelectedItem,
  menuHeader,
  placeholder,
  shouldFilter = true,
  disableTextInput,
  onEnterPress,
  disabled,
  height,
  width,
  rightButton,
  rightButtonDisabled,
  onPressRightButton,
  dropdownAsOverlay,
  selectedItemValue,
  renderSelectedItem,
}: Props<T>) {
  const inputItems = useMemo(() => {
    if (shouldFilter) {
      return items.filter((item) => item.label.toLowerCase().includes(value.toLowerCase()));
    }

    return items;
  }, [items, value, shouldFilter]);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    closeMenu,
  } = useCombobox<AutoCompleteInputItem>({
    inputValue: value,
    itemToString: (item) => (item ? item.label : ""),
    items: inputItems,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        if (onSelectedItem) {
          // @ts-ignore
          onSelectedItem(selectedItem);
        } else {
          onChange(selectedItem.value);
        }

        closeMenu();
      }
    },
  });

  const itemGroups = [...new Set(items.map((i) => i.group))];
  const groupedItems = itemGroups.map((g) => ({ label: g, items: items.filter((i) => i.group === g) }));

  const renderComboInputBox = isOpen || !renderSelectedItem || !selectedItemValue;

  return (
    <Flex direction="column">
      <Flex
        {...getComboboxProps({}, { suppressRefError: true })}
        direction="column"
        height={dropdownAsOverlay ? height || "40px" : "auto"}
        overflowY="visible"
        width={width}
      >
        <HStack>
          {renderCustomButton && renderCustomButton(openMenu)}
          {!renderCustomButton && (
            <InputGroup bg="white" size="md" width="100%" borderRadius={5}>
              {renderComboInputBox && (
                <ComboboxInput
                  disabled={disabled}
                  onClick={openMenu}
                  onFocus={openMenu}
                  isReadOnly={disableTextInput}
                  {...getInputProps(
                    {
                      onChange: (e) => {
                        onChange((e.target as HTMLInputElement).value);
                      },
                    },
                    { suppressRefError: true }
                  )}
                  cursor={disableTextInput ? "pointer" : "default"}
                  placeholder={placeholder || "Search..."}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && onEnterPress) {
                      onEnterPress();
                      closeMenu();
                    }
                  }}
                  height={height}
                />
              )}

              {!isOpen && renderSelectedItem && selectedItemValue && renderItem && (
                <Button variant="outline" onClick={openMenu} height={height} width="100%">
                  <Flex
                    width="100%"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    fontWeight="400"
                    textAlign="left"
                  >
                    {(() => {
                      const selectedItem = items.find((i) => i.value === selectedItemValue);
                      return selectedItem ? renderItem(selectedItem, true) : null;
                    })()}
                  </Flex>
                </Button>
              )}
              <InputRightElement width={isLoading ? "60px" : undefined} height="100%" alignItems="center">
                <HStack
                  cursor={disabled ? "not-allowed" : "pointer"}
                  {...(disabled ? {} : getToggleButtonProps())}
                  mr={2}
                >
                  {isLoading && <Spinner size="sm" mr={1} />}
                  <Flex>{isOpen ? <Icon as={FaChevronUp} /> : <Icon as={FaChevronDown} />}</Flex>
                </HStack>
              </InputRightElement>
            </InputGroup>
          )}
          {rightButton && (
            <Button
              disabled={rightButtonDisabled}
              colorScheme="blue"
              onClick={() => {
                onPressRightButton?.();
                closeMenu();
              }}
            >
              Add
            </Button>
          )}
        </HStack>
        <Flex>
          <ComboboxList
            py={inputItems.length ? 2 : 0}
            shadow="md"
            isOpen={isOpen}
            {...getMenuProps()}
            flex={1}
            overflowY="auto"
            mt={0}
            zIndex="popover"
            width="100%"
            maxHeight={350}
          >
            {menuHeader && <Box>{menuHeader}</Box>}

            {groupedItems.map((group, groupIndex) => (
              <Box key={`group-${group.label}`}>
                {!!group.label && (
                  <Text fontWeight="bold" fontSize="sm" pl={4} pt={2} color="gray.400">
                    {(group.label || "").toUpperCase()}
                  </Text>
                )}

                {group.items.map((item, index) => {
                  let offset = 0;

                  for (let i = 0; i < groupIndex; i++) {
                    offset += groupedItems[i].items.length;
                  }

                  return (
                    <ComboboxItem
                      {...getItemProps({ item, index: offset + index })}
                      itemIndex={offset + index}
                      highlightedIndex={highlightedIndex}
                      key={item.value}
                      backgroundColor={selectedItemValue === item.value ? "gray.50" : "auto"}
                    >
                      {renderItem ? renderItem(item, false) : <Text noOfLines={1}>{item.label}</Text>}
                    </ComboboxItem>
                  );
                })}
              </Box>
            ))}
          </ComboboxList>
        </Flex>
      </Flex>
    </Flex>
  );
}
