import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useGetAllPropertyTags } from "../../lib/api/properties/hooks";
import AutoCompleteInput from "../autocomplete-input/AutoCompleteInput";

const TAG_REGEX = /^[a-zA-Z0-9_ -]*$/g;

interface PropertyTagListProps {
  title?: string;
  tags?: string[];
  onTagsUpdated?: (tags: string[]) => void;
  editable?: boolean;
  enableCustomTags?: boolean;
  rightButton?: boolean;
  size?: "xs" | "sm" | "md";
  overflow?: "visible" | "grouped";
}

export const PropertyTagList = ({
  title,
  tags = [],
  onTagsUpdated,
  editable,
  enableCustomTags,
  rightButton,
  size = "md",
  overflow = "visible",
}: PropertyTagListProps) => {
  const [tagInput, setTagInput] = useState<string>("");
  const { tags: existingTags, isLoading } = useGetAllPropertyTags({
    retry: false,
    enabled: Boolean(editable),
  });
  const tagListContainerRef = useRef<HTMLDivElement>(null);

  // Force a re-render on window resize
  const rerender = useReducer(() => ({}), {})[1];
  useEffect(() => {
    const handleResize = () => {
      rerender();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const addTag = (tag: string) => {
    if (!tag.length) {
      return;
    }
    if (tags.includes(tagInput)) {
      return;
    }

    if (onTagsUpdated) {
      onTagsUpdated([...tags, tag]);
    }
    setTagInput("");
  };

  const unusedExistingTags = useMemo(() => (existingTags || []).filter((t) => !tags.includes(t)), [
    existingTags,
    tags,
  ]);

  let placeholderText = "Select tags";
  if (enableCustomTags) {
    placeholderText = "Add a new tag - (Press Enter to add)";
  } else if (existingTags?.length === 0) {
    placeholderText = "None of your properties have tags";
  }

  let validationError = "";
  if (tags.includes(tagInput)) {
    validationError = "Tag already exists";
  } else if (tagInput.match(TAG_REGEX) === null) {
    validationError = "Tag can only contain letters, numbers, spaces and dashes.";
  }

  // Calculate offset and count for "+X More" tag if tags overflow and overflow=grouped is set
  let overflowCount = 0,
    overflowOffset = 0;
  if (overflow === "grouped" && tagListContainerRef.current?.children.length) {
    for (let i = 0; i < tagListContainerRef.current.children.length; i++) {
      const curr = tagListContainerRef.current.children[i] as HTMLDivElement;
      if (curr.offsetTop > 1) {
        // Element has been wrapped
        overflowCount++;
      } else {
        // Set the offset to hit the right edge of the last non-wrapped element
        overflowOffset = curr.offsetLeft + curr.offsetWidth;
      }
    }
  }

  return (
    <FormControl display="flex" width="100%" isInvalid={!!validationError} mb={4}>
      <VStack width="100%" alignItems="flex-start">
        {title && <FormLabel>{title}</FormLabel>}
        {editable && (
          <VStack alignItems="stretch" width="100%">
            <AutoCompleteInput
              placeholder={placeholderText}
              disabled={existingTags?.length === 0 && !enableCustomTags}
              shouldFilter={false}
              onChange={setTagInput}
              items={unusedExistingTags
                .filter((tag) => !tag || tag.toLowerCase().includes(tagInput.toLowerCase()))
                .map((tag) => ({ label: tag, value: tag }))}
              value={tagInput}
              isLoading={isLoading}
              onSelectedItem={(item) => addTag(item.value)}
              disableTextInput={!enableCustomTags}
              onEnterPress={() => {
                if (editable && enableCustomTags && !validationError) {
                  addTag(tagInput);
                }
              }}
              rightButton={rightButton}
              rightButtonDisabled={!!validationError}
              onPressRightButton={() => addTag(tagInput)}
            />
            <FormErrorMessage>{validationError}</FormErrorMessage>
          </VStack>
        )}
        <Box position="relative" width="100%" overflow="visible">
          <Box
            ref={tagListContainerRef}
            display="flex"
            maxWidth={overflow === "visible" ? "100%" : "calc(100% - 100px)"}
            height={
              overflow === "visible"
                ? "auto"
                : {
                    xs: 6,
                    sm: 5,
                    md: 10,
                  }[size]
            }
            justifyContent="flex-start"
            alignItems="flex-start"
            flexWrap="wrap"
            gap={2}
          >
            {tags.map((tag, i) => (
              <Tag
                text={tag}
                deleteTag={() => {
                  onTagsUpdated && onTagsUpdated(tags.filter((t) => t !== tag));
                }}
                size={size}
                editable={editable}
                key={i}
              />
            ))}
          </Box>
          {overflowCount > 0 && (
            <Tooltip label={tags.slice(tags.length - overflowCount).join(", ")}>
              <Box left={overflowOffset + 8} position="absolute" top={0}>
                <Tag
                  text={`+${overflowCount} More`}
                  size={size}
                  deleteTag={() => {}}
                  editable={false}
                  isOverflowTag={true}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      </VStack>
    </FormControl>
  );
};

const Tag = ({
  text,
  editable,
  size,
  deleteTag,
  isOverflowTag,
}: {
  text: string;
  editable?: boolean;
  size: "xs" | "sm" | "md";
  deleteTag: () => void;
  isOverflowTag?: boolean;
}) => {
  return (
    <HStack
      bg={isOverflowTag ? "black" : "lightGray"}
      borderRadius={
        {
          xs: 5,
          sm: 5,
          md: 10,
        }[size]
      }
      pl={editable ? 3 : 2}
      pr={2}
      py={
        {
          xs: 0.5,
          sm: 0.5,
          md: "auto",
        }[size]
      }
      h={
        {
          xs: "auto",
          sm: "auto",
          md: 10,
        }[size]
      }
      m={0}
      border="1px solid"
      borderColor={isOverflowTag ? "transparent" : "gray.100"}
    >
      <Text
        fontSize={
          {
            xs: 12,
            sm: "auto",
            md: "auto",
          }[size]
        }
        whiteSpace="nowrap"
        color={isOverflowTag ? "white" : "auto"}
      >
        {text}
      </Text>
      {editable && (
        <IconButton
          size="xs"
          variant="ghost"
          aria-label={`Remove ${text} tag`}
          onClick={deleteTag}
          icon={<CloseIcon />}
        />
      )}
    </HStack>
  );
};
